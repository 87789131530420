import React from "react"

import Layout from "../layouts"

import "./reward.css"
import { getUser, getUserSelf } from "../api/user"
import { getCookie, isPC, queryParse } from "../utils/helper"
import { MEDIA_INTRO, MEMBERSHIP_SIDEBAR, TOKEN } from "../utils/constant"
import { Avatar, Modal, message } from "antd"
import { navigate } from "gatsby"
import { InfoCircleTwoTone } from "@ant-design/icons"

class Reward extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: "",
      hasRegister: false,
    }
    this.layout = null;
  }

  componentDidMount() {
    this.initUser();
  }

  initUser = async () => {
    const param = queryParse()
    if (!param.code) {
      navigate(MEMBERSHIP_SIDEBAR.INVITE.url);
      return;
    }
    const res = await getUser(param.code)
    if (!res.data.success) {
      navigate(MEMBERSHIP_SIDEBAR.INVITE.url);
      return;
    }
    this.setState({ username: res.data.data.username })

    this.initLogin(param.code);
  }

  initLogin = async (userOutId) => {
    const token = getCookie(TOKEN);
    if (!token) {
      return;
    }
    this.setState({ hasRegister: true });

    const res = await getUserSelf();
    if (userOutId === res.data.data.inviteUserOutId) {
      Modal.confirm({
        title: "恭喜您帮助好友完成助力",
        okText: "邀请更多好友",
        cancelText: "取消",
        centered: true,
        icon: <InfoCircleTwoTone />,
        onOk: async () => {
          navigate(MEMBERSHIP_SIDEBAR.INVITE.url)
        },
      })
    } else {
      Modal.confirm({
        title: "已经注册的用户无法进行助力",
        okText: "邀请更多好友",
        cancelText: "取消",
        centered: true,
        icon: <InfoCircleTwoTone />,
        onOk: async () => {
          navigate(MEMBERSHIP_SIDEBAR.INVITE.url)
        },
      })
    }
  }

  handleLogin = () => {
    if (!isPC()) {
      message.warning("mdnice非移动端工具，请在电脑网页上进行助力")
      return;
    }
    this.layout.handleSignInLoginDisplay(true);
  }

  render() {
    return (
      <Layout isSpacerDisplay={true} title="会员邀请" ref={(node) => { this.layout = node; }}>
        <div className="reward-container">
          <div className="reward-pc-content">
            <div className="reward-img-container"></div>
            <div className="reward-header">
              <div className="reward-help-container">
                <p className="reward-help-desc">
                  <span>你的朋友 </span>
                  <span className="reward-help-username">“{this.state.username}”</span>
                  <span> 邀请你为TA的写作助力</span></p>
              </div>
              <div className="reward-intro-container">
                <p className="reward-intro-text">mdnice是一款 Markdown 编辑器，包含客户端、网页端和浏览器插件</p>
                <p className="reward-intro-text">支持微信公众号一键排版、主题切换、多平台分发、ai写作等功能</p>
                <p className="reward-intro-text">全网唯一支持数学公式发布到微信公众号，提供图片和文章存储管理</p>
                <p className="reward-intro-text">排版100万篇文章，为10万公众号作者助力</p>
              </div>
              <div className="reward-intro-mobile-container">
                <p className="reward-intro-text">mdnice是一款 Markdown 编辑器</p>
                <p className="reward-intro-text">包含客户端、网页端和浏览器插件</p>
                <p className="reward-intro-text">支持微信公众号一键排版、主题切换等功能</p>
                <p className="reward-intro-text">全网唯一支持数学公式发布到微信公众号</p>
                <p className="reward-intro-text">排版100万篇文章，为10万公众号作者助力</p>
              </div>
              {this.state.hasRegister ?
                <button className="help-btn-disable">注册完成助力</button> :
                <button className="help-btn" onClick={this.handleLogin}>注册完成助力</button>}
            </div>
            <div className="reward-more-container">
              <p className="reward-more-header">更多介绍</p>
              {MEDIA_INTRO.map(item => (
                <div key={item.name} className="mdnice-homepage-media-item">
                  <div className="mdnice-homepage-media-content">
                    <h3 className="mdnice-homepage-media-wechat-name">
                      {item.name}
                    </h3>
                    <p className="mdnice-homepage-media-wechat-description">
                      {item.description}
                    </p>
                    <a
                      className="mdnice-homepage-media-wechat-link"
                      target="_blank"
                      href={item.link}
                    >
                      查看原文
                    </a>
                  </div>
                  <div className="mdnice-homepage-media-avatar">
                    <Avatar
                      size={{ xs: 64, sm: 64, md: 80, lg: 80, xl: 80, xxl: 80 }}
                      src={item.avatar}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Reward
